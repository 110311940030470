var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { Dialog, Box, Paper } from '@mui/material';
//
import { varFade } from './variants';
export default function DialogAnimate(_a) {
    var _b = _a.open, open = _b === void 0 ? false : _b, variants = _a.variants, onClose = _a.onClose, children = _a.children, sx = _a.sx, other = __rest(_a, ["open", "variants", "onClose", "children", "sx"]);
    return (_jsx(AnimatePresence, { children: open && (_jsx(Dialog, __assign({ fullWidth: true, maxWidth: "xs", open: open, onClose: onClose, PaperComponent: function (props) { return (_jsxs(Box, __assign({ component: m.div }, (variants ||
                varFade({
                    distance: 120,
                    durationIn: 0.32,
                    durationOut: 0.24,
                    easeIn: 'easeInOut',
                }).inUp), { sx: {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                } }, { children: [_jsx(Box, { onClick: onClose, sx: { width: '100%', height: '100%', position: 'fixed' } }), _jsx(Paper, __assign({ sx: sx }, props, { children: props.children }))] }))); } }, other, { children: children }))) }));
}
